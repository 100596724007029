// #region Global Imports
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { MembershipType } from "@Interfaces/enums";
import { IStore } from "@Redux/IStore";
import { theme } from "@Definitions/Styled";
import { IconButton, Badge, Text } from "@Components/Basic";
import { OnlineFriends, MobileNavigationMenu, MobileProfileMenu } from "./Components";
import { IconBlockButton, Tooltip, Avatar, ProfilePhotoUpload, HoverToolTip } from "@Components";
import { useScreenSize, Preset, ActionConsts, getContactUrl } from "@Definitions";
import {
    ChatIcon,
    ConversationIcon,
    WinkIcon,
    ViewIcon,
    FriendsIcon,
    SearchIcon,
    MenuIcon,
    CoinLightIcon,
    StarIcon
} from "@Icons";
import { ProfileActions, CommonActions, AccountActions } from "@Actions";
import {
    Grid,
    Container,
    CoinCountText,
    CointText,
    ButtonContent,
    Logo,
    Left,
    Middle,
    Right,
    ProfileAvatarIcon,
    ProfileMenuIcon,
    ProfileItemToolTip,
    ProfileItemText,
    StyledIcon,
    Profile,
    ProfileItem,
    MenuItemToolTip,
    MobileContainer,
    HamburgerMenuIcon,
    HamburgerMobileMenuIcon,
    NavigationMenu,
    NavItem,
    Label,
    HoverToolTipWrapper,
} from "./styled";
import { IHeader } from "./Header";
// #endregion Local Imports

const Header: React.FunctionComponent<IHeader.IProps> = (props): JSX.Element => {
    const { t } = props;

    const dashboard = useSelector((state: IStore) => state.dashboard);
    const interaction = useSelector((state: IStore) => state.interaction.interactions);
    const profile = useSelector((state: IStore) => state.profile.self);
    const showPhotoUpload = useSelector((state: IStore) => state.common.showPhotoUpload);
    const coins = useSelector((state: IStore) => state.common.coin);

    const [navMenu, setNavMenu] = useState<boolean>(false);
    const [profileMenu, setProfileMenu] = useState<boolean>(false);
    const [shouldRender, setShouldRender] = useState<boolean>(false);

    const dispatch = useDispatch();
    const logout = async () => {
        dispatch(AccountActions.Logout());
    };

    const uploadPhoto = async () => {
        dispatch(await ProfileActions.SetUploadOptions("edit"));
        dispatch(await CommonActions.SetShowUploadPhoto(true));
    };

    const winCredit = () => {
        dispatch({
            type: ActionConsts.Common.SetWinCreditModal,
            payload: true,
        });
    };

    useEffect(() => {
        setShouldRender(true);
    }, []);

    const screenSize = useScreenSize();

    const isMobile = screenSize.includes("small");
    const isContainer = screenSize === "medium" ? "" : "container";

    const onlineFriends = interaction.onlineFriends.Interactions;
    const {
        NewWinkCount,
        NewProfileViewCount,
        NewRequestCount,
        NewChatsCount,
        NewPrioritizedCount,
    } = dashboard.notificationCounts;

    const badgeCountObject: { [key: string]: number } = {
        NewRequestCount: NewRequestCount + NewPrioritizedCount,
        NewPrioritizedCount,
        NewConversationCount: NewChatsCount,
        NewWinkCount,
        NewProfileViewCount,
        Friends: interaction.onlineFriends.Count,
    };

    const domain = "//www.siberalem.com";

    const renderDesktop = () => (
        <Container>
            {showPhotoUpload && <ProfilePhotoUpload t={t} />}
            <Grid className={isContainer}>
                <Left>
                    <Link href="/dashboard?omitScrollKeeper=true" as="/dashboard">
                        <a>
                            <Logo
                                src="/icons/siberalem-hearts-colored.svg"
                                alt="siberalem logo hearts"
                            />
                        </a>
                    </Link>
                    <NavigationMenu>
                        <NavItem key="header-icons-chat" data-cy="header-requests-btn">
                            <Link
                                href="/inbox/[slug]"
                                as={
                                    NewPrioritizedCount > 0
                                        ? "/inbox/prioritized"
                                        : "/inbox/requests"
                                }
                            >
                                <a>
                                    <Badge
                                        count={badgeCountObject.NewRequestCount}
                                        color={theme.colors.badgeRed}
                                        hoverColor={theme.colors.badgeHoverRed}
                                    >
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <ChatIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </Badge>
                                </a>
                            </Link>
                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.sohbet istekleri")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                        <NavItem key="header-icons-conversation">
                            <Link href="/inbox/[slug]" as="/inbox/messages">
                                <a>
                                    <Badge
                                        count={badgeCountObject.NewConversationCount}
                                        color={theme.colors.badgeRed}
                                        hoverColor={theme.colors.badgeHoverRed}
                                    >
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <ConversationIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </Badge>
                                </a>
                            </Link>

                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.sohbetlerim")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                        <NavItem key="header-icons-wink">
                            <Link href="/winks" as="/winks">
                                <a>
                                    <Badge
                                        count={badgeCountObject.NewWinkCount}
                                        color={theme.colors.badgeRed}
                                        hoverColor={theme.colors.badgeHoverRed}
                                    >
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <WinkIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </Badge>
                                </a>
                            </Link>
                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.göz kırpmalarım")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                        <NavItem key="header-icons-view">
                            <Link href="/profilevisitor" as="/profilevisitor">
                                <a>
                                    <Badge
                                        count={badgeCountObject.NewProfileViewCount}
                                        color={theme.colors.badgeRed}
                                        hoverColor={theme.colors.badgeHoverRed}
                                    >
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <ViewIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </Badge>
                                </a>
                            </Link>
                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.profilime bakanlar")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                        <NavItem key="header-icons-friends">
                            {badgeCountObject.Friends > 0 ? (
                                <div className="ml-3">
                                    <Badge
                                        count={badgeCountObject.Friends}
                                        color={theme.colors.badgeGreen}
                                        hoverColor={theme.colors.badgeHoverGreen}
                                    >
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <FriendsIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </Badge>

                                    <Tooltip placement="bottom" customStyle={MenuItemToolTip}>
                                        <OnlineFriends t={t} friendList={onlineFriends} />
                                    </Tooltip>
                                </div>
                            ) : (
                                <Link href="/friends" as="/friends">
                                    <a>
                                        <IconButton
                                            renderIcon={() => (
                                                <StyledIcon>
                                                    <FriendsIcon width="36px" height="36px" />
                                                </StyledIcon>
                                            )}
                                        />
                                    </a>
                                </Link>
                            )}
                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.arkadaşlar")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                        <NavItem key="header-icons-search" data-cy="search-btn">
                            <Link href="/search" as="/search">
                                <a>
                                    <IconButton
                                        renderIcon={() => (
                                            <StyledIcon>
                                                <SearchIcon width="36px" height="36px" />
                                            </StyledIcon>
                                        )}
                                    />
                                </a>
                            </Link>
                            <HoverToolTipWrapper>
                                <HoverToolTip>
                                    <Text size="small" color="gray">
                                        {t("common:Header.Menu Items.arama")}
                                    </Text>
                                </HoverToolTip>
                            </HoverToolTipWrapper>
                        </NavItem>
                    </NavigationMenu>
                </Left>
                <Right>
                    <ButtonContent>
                        {profile?.MembershipType === MembershipType.normal && (
                            <Link
                                href="/payments/[type]/credit-card?source=menu link"
                                as="/payments/gold/credit-card"
                            >
                                <IconBlockButton
                                    href="/payments/gold/credit-card?source=menu link"
                                    variant="contained"
                                    color="white"
                                    iconClick={() => console.log("IconBlockButton pressed")}
                                    hoverColor={theme.gradients.goldButton}
                                    hoverTextColor={theme.colors.white}
                                    startIcon={<StarIcon width="24px" height="24px" color={theme.colors.gray}/>}
                                    cy="gold-btn"
                                >
                                    <Label>{t("common:Header.buttons.gold")}</Label>
                                </IconBlockButton>
                            </Link>
                        )}
                    </ButtonContent>
                    <ButtonContent>
                        <Link
                            href="/payments/[type]/credit-card?source=menu link"
                            as="/payments/coin/credit-card"
                        >
                            <IconBlockButton
                                href="/payments/coin/credit-card"
                                hoverColor={theme.gradients.goldButton}
                                hoverTextColor={theme.colors.white}
                                color="white"
                                variant="contained"
                                startIcon={<CoinLightIcon width="24px" height="24px" color={theme.colors.gray}/>}
                                cy="coin-btn"
                            >
                                <CoinCountText>{coins}</CoinCountText>
                                {coins <= 200 && (
                                    <CointText>{t("common:Header.buttons.coin")}</CointText>
                                )}
                            </IconBlockButton>
                        </Link>
                    </ButtonContent>
                    <Profile>
                        <ProfileMenuIcon className="hambuger-menu">
                            <IconButton
                                renderIcon={() => (
                                    <HamburgerMenuIcon>
                                        <MenuIcon width="38px" height="38px" />
                                    </HamburgerMenuIcon>
                                )}
                                onIconClick={() => console.log("")}
                            />
                        </ProfileMenuIcon>
                        <ProfileAvatarIcon className="avatar">
                            <Avatar
                                type="default"
                                src={`${profile?.Photo.Url}${Preset.thumb}`}
                                alt="avatar"
                            />
                        </ProfileAvatarIcon>

                        <Tooltip placement="bottom-end" customStyle={ProfileItemToolTip}>
                            <ProfileItem key="nav-items-username">
                                <Link href="/profileEdit/[id]" as={`/profileEdit/${profile?.Id}`}>
                                    <a>
                                        <ProfileItemText>{profile?.Username}</ProfileItemText>
                                    </a>
                                </Link>
                            </ProfileItem>
                            <ProfileItem key="nav-items-photo">
                                <ProfileItemText onClick={() => uploadPhoto()}>
                                    {t(`common:Header.Profile Menu Items.fotoğraf ekleme`)}
                                </ProfileItemText>
                            </ProfileItem>
                            <ProfileItem key="nav-items-settings">
                                <Link href="/settings" as="/settings">
                                    <a>
                                        <ProfileItemText>
                                            {t(`common:Header.Profile Menu Items.ayarlar`)}
                                        </ProfileItemText>
                                    </a>
                                </Link>
                            </ProfileItem>
                            <ProfileItem key="nav-items-bans">
                                <Link href="/banned" as="/banned">
                                    <a>
                                        <ProfileItemText>
                                            {t(`common:Header.Profile Menu Items.yasaklılar`)}
                                        </ProfileItemText>
                                    </a>
                                </Link>
                            </ProfileItem>
                            <ProfileItem key="nav-items-gold">
                                <Link
                                    href="/payments/[type]/credit-card"
                                    as="/payments/gold/credit-card"
                                >
                                    <a>
                                        <ProfileItemText>
                                            {t(
                                                `common:Header.Profile Menu Items.gold üyelik satın al`
                                            )}
                                        </ProfileItemText>
                                    </a>
                                </Link>
                            </ProfileItem>
                            <ProfileItem key="nav-items-coin">
                                <Link
                                    href="/payments/[type]/credit-card"
                                    as="/payments/coin/credit-card"
                                >
                                    <a>
                                        <ProfileItemText>
                                            {t(`common:Header.Profile Menu Items.kredi satın al`)}
                                        </ProfileItemText>
                                    </a>
                                </Link>
                            </ProfileItem>
                            <ProfileItem key="nav-items-gift">
                                <ProfileItemText onClick={winCredit}>
                                    {t(`common:Header.Profile Menu Items.ücretsiz kredi kazan`)}
                                </ProfileItemText>
                            </ProfileItem>
                            <ProfileItem key="nav-items-help">
                                <a
                                    href={`${domain}/yardim-merkezi`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ProfileItemText>
                                        {t(`common:Header.Profile Menu Items.yardım`)}
                                    </ProfileItemText>
                                </a>
                            </ProfileItem>
                            <ProfileItem key="nav-items-logout">
                                <ProfileItemText onClick={() => logout()}>
                                    {t(`common:Header.Profile Menu Items.çıkış`)}
                                </ProfileItemText>
                            </ProfileItem>
                        </Tooltip>
                    </Profile>
                </Right>
            </Grid>
        </Container>
    );

    const { Friends, ...newBadgeCountObj } = badgeCountObject;

    const badgeTotalNum = Object.values(newBadgeCountObj).reduce((a, b) => a + b, 0);

    const renderMobile = () => (
        <MobileContainer>
            {showPhotoUpload && <ProfilePhotoUpload t={t} />}
            <Left isMobile>
                <Badge
                    count={badgeTotalNum}
                    color={theme.colors.badgeRed}
                    hoverColor={theme.colors.badgeHoverRed}
                    cy="hamburgerMobileMenu-btn"
                >
                    <IconButton
                        cy="mobile-hamburger-btn"
                        renderIcon={() => (
                            <HamburgerMobileMenuIcon>
                                <MenuIcon width="42px" height="42px" />
                            </HamburgerMobileMenuIcon>
                        )}
                        onIconClick={() => setNavMenu(!navMenu)}
                    />
                </Badge>
            </Left>
            <Middle>
                <Link href="/dashboard">
                    <a>
                        <Logo
                            src="/icons/siberalem-hearts-colored.svg"
                            alt="siberalem logo hearts"
                            isMobile
                        />
                    </a>
                </Link>
            </Middle>
            <Right
                onClick={() => setProfileMenu(!profileMenu)}
                isMobile
                data-cy="setProfileMenu-btn"
            >
                <Avatar
                    type="default"
                    src={`${profile?.Photo.Url}${Preset.thumb}`}
                    alt="siberalem logo avatar"
                />
            </Right>

            {navMenu && (
                <MobileNavigationMenu
                    t={t}
                    onClose={() => setNavMenu(!navMenu)}
                    badgeCountObject={badgeCountObject}
                />
            )}
            {profileMenu && profile && (
                <MobileProfileMenu
                    username={profile.Username}
                    userId={profile.Id}
                    contactUrl={getContactUrl()}
                    t={t}
                    onClose={() => setProfileMenu(!profileMenu)}
                    logout={logout}
                    uploadPhoto={uploadPhoto}
                    winCredit={winCredit}
                />
            )}
        </MobileContainer>
    );

    return <>{shouldRender ? isMobile ? renderMobile() : renderDesktop() : <Container />}</>;
};

export { Header };
