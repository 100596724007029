// #region Global Imports
import React from "react";
import Link from "next/link";
import { useSelector } from "react-redux";
// #endregion Global Imports

// #region Local Imports
import { IconButton } from "@Components/Basic";
import { Modal } from "@Components";
import { CircleCloseIcon } from "@Icons";
import {
    Container,
    StyledIcon,
    CloseRight,
    TopNav,
    NavItem,
    NavContent,
    NavText,
    HR,
} from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IMobileProfileMenu } from "./MobileProfileMenu";
import { IStore } from "@Interfaces";
// #endregion Interface Imports

const MobileProfileMenu: React.FunctionComponent<IMobileProfileMenu.IProps> = ({
    t,
    onClose,
    username,
    userId,
    contactUrl,
    logout,
    uploadPhoto,
    winCredit,
}): JSX.Element => {
    const coins = useSelector((state: IStore) => state.common.coin);
    const { useState } = React;
    const [fadeIn, setFadeIn] = useState(true);

    const handleClose = () => {
        setFadeIn(false);
        setTimeout(() => {
            onClose();
        }, 500);
    };

    const domain = "https://www.siberalem.com";

    return (
        <Modal>
            <Container isFadeIn={fadeIn}>
                <TopNav>
                    <CloseRight>
                        <IconButton
                            renderIcon={() => (
                                <StyledIcon>
                                    <CircleCloseIcon width="36px" height="36px" />
                                </StyledIcon>
                            )}
                            onIconClick={() => handleClose()}
                        />
                    </CloseRight>
                </TopNav>
                <NavContent isFadeIn={fadeIn}>
                    <NavItem key="nav-items-username">
                        <NavText onClick={() => handleClose()}>
                            <Link
                                href="/profileEdit/[id]?source=profile view list"
                                as={`/profileEdit/${userId}`}
                            >
                                <a>{username}</a>
                            </Link>
                        </NavText>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-photo">
                        <NavText
                            onClick={() => {
                                uploadPhoto();
                                handleClose();
                            }}
                        >
                            {t(`common:Header.Profile Menu Items.fotoğraf ekleme`)}
                        </NavText>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-settings">
                        <Link href="/settings" as="/settings">
                            <a>
                                <NavText onClick={() => handleClose()}>
                                    {t(`common:Header.Profile Menu Items.ayarlar`)}
                                </NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-bans">
                        <Link href="/banned" as="/banned">
                            <a>
                                <NavText onClick={() => handleClose()}>
                                    {t(`common:Header.Profile Menu Items.yasaklılar`)}
                                </NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-gold" data-cy="goldMobile-btn">
                        <Link href="/payments/[type]/credit-card" as="/payments/gold/credit-card">
                            <a>
                                <NavText onClick={() => handleClose()}>
                                    {t(`common:Header.Profile Menu Items.gold üyelik satın al`)}
                                </NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-coin" data-cy="coinMobile-btn">
                        <Link href="/payments/[type]/credit-card" as="/payments/coin/credit-card">
                            <a className="w-100">
                                <NavText
                                    className="d-flex justify-content-between"
                                    onClick={() => handleClose()}
                                >
                                    <span>
                                        {t(`common:Header.Profile Menu Items.kredi satın al`)}
                                    </span>
                                    <span className="ml-1">{`(${coins})`}</span>
                                </NavText>
                            </a>
                        </Link>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-gift">
                        <NavText
                            onClick={() => {
                                winCredit();
                                handleClose();
                            }}
                        >
                            {t(`common:Header.Profile Menu Items.ücretsiz kredi kazan`)}
                        </NavText>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-help">
                        <a
                            href={`${domain}/yardim-merkezi`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <NavText>{t(`common:Header.Profile Menu Items.yardım`)}</NavText>
                        </a>
                    </NavItem>
                    <HR />
                    <NavItem key="nav-items-logout">
                        <NavText
                            onClick={() => {
                                logout();
                                handleClose();
                            }}
                        >
                            {t(`common:Header.Profile Menu Items.çıkış`)}
                        </NavText>
                    </NavItem>
                </NavContent>
            </Container>
        </Modal>
    );
};

export { MobileProfileMenu };
